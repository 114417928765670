import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrHinhLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Hinh"
    institution="Dr. Hinh"
    referralCode="DRHINH"
  />
)

export default DrHinhLandingPage
